
import React, { useState, useRef, Fragment, useEffect, createRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import { sliceEvents, createPlugin } from '@fullcalendar/core';
import moment from 'moment';



// https://fullcalendar.io/docs/google-calendar

// Calendarpg는 이제 Calendar로 이름을 변경하였습니다.
const Calendar = React.memo(({ Calendarid, textContent }) => {
    const calendarRef = useRef(null); // Create a ref for the 

    // props로 전달된 Calendarid를 변수로 가져옵니다.


    // Apikey는 환경 변수를 이용해 숨겨야함
    const apiKey = process.env.REACT_APP_GOOGLECALENDAR_API_KEY

    const today_date = new Date()
    let initial_date = new Date()
    initial_date.setDate(today_date.getDate() - 15)


  
    useEffect(() => {
        if (!calendarRef.current) return;
        var header_elements = document.querySelectorAll('.fc-col-header-cell-cushion');
        var body_elements = document.querySelectorAll('.fc-daygrid-day-frame')
        var i;
        var j;
        var calendar_body = document.querySelectorAll('.fc-scroller');
        for (j = 0 ; j<calendar_body.length;j++){
            calendar_body[j].scrollTo({left:400, behavior:'smooth'})
        }
    

     

        for (i = 0; i < header_elements.length; i++) {
            let changeToDate = new Date(header_elements[i].getAttribute('aria-label'))
            let weekday = changeToDate.getDay()
            let today = new Date()

            if (weekday === 0) {
                header_elements[i].innerHTML = changeToDate.getDate().toString() + '\n' + '日'
            } else if (weekday === 1) {
                header_elements[i].innerHTML = changeToDate.getDate().toString() + '\n' + '月'

            } else if (weekday === 2) {
                header_elements[i].innerHTML = changeToDate.getDate().toString() + '\n' + '火'

            } else if (weekday === 3) {
                header_elements[i].innerHTML = changeToDate.getDate().toString() + '\n' + '水'

            } else if (weekday === 4) {
                header_elements[i].innerHTML = changeToDate.getDate().toString() + '\n' + '木'

            } else if (weekday === 5) {
                header_elements[i].innerHTML = changeToDate.getDate().toString() + '\n' + '金'

            } else if (weekday === 6) {
                header_elements[i].innerHTML = changeToDate.getDate().toString() + '\n' + '土'

            }


            if (changeToDate.getMonth() === today.getMonth() && changeToDate.getDate() === today.getDate() ){
                // header_elements[i].style.background = 'linear-gradient(#fff, #fff) no-repeat center/4px 100%'
                body_elements[i].classList.add('todayline');
                header_elements[i].classList.add('today');}
            }
        
    },[Calendarid]); 




    return (
        <div className="cal-container" ref={calendarRef}>
            <div className="content-text"> <h3 className="calendarname">{textContent} </h3>
            </div>
            <FullCalendar
                plugins={[dayGridPlugin, googleCalendarPlugin, scrollGridPlugin]}
                initialView="dayGridFourWeek"
                views={{
                    dayGridFourWeek: {
                        type: 'dayGrid',
                        duration: { days: 45 },
                        dayHeaderFormat: { weekday: 'short', day: 'numeric', omitCommas: true },
                    }
                }}
                headerToolbar={false}
                googleCalendarApiKey={apiKey}
                events={{
                    googleCalendarId: Calendarid, // 변수명만 사용합니다.
                }}
                eventDisplay={'block'}
                // height={'800px'}
                dayMinWidth={'40'}
                initialDate={initial_date}
                eventOverlap={false}
                displayEventEnd={true}
                eventContent={renderEventContent}
                eventClick={eventClicker}
                stickyFooterScrollbar = {true}
                eventDidMount={afterMount}
                
            />
        </div>
    );
});


function eventClicker(event){
    event.jsEvent.preventDefault();
    // 클릭 이벤트 제거

}

function afterMount(event){
    var aTags = document.querySelectorAll(".fc-event")
    var i;
    for (i = 0; i < aTags.length; i++) {
        try{
            aTags[i].removeAttribute('href') // 손모양 조차 안뜨게
            //aTags[i].href = 'javascript:;' // 손모양은 뜨게, 나중에 javascript 대신에 다른 링크 집어 넣으면 그대로 동작
            }catch(e){
                console.log(e)
            }
    }


}

function calculateTimeLeft(startDate, endDate) {
    const diff = endDate.getTime() - startDate.getTime();

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));  
    return days;
  }


function calculateTimeDifference(startDate, endDate) {
    const diff = endDate.getTime() - startDate.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  
    return `${days}d ${hours}h`;
  }

function renderEventContent(eventInfo) {
    let endTime = new Date(eventInfo.event.end)
    let nowTime = new Date()
    let startTime = new Date(eventInfo.event.start)
    const eventDuration = calculateTimeLeft(startTime, endTime);
    const leftTime = calculateTimeLeft(nowTime, endTime);
    const leftTime2 = calculateTimeLeft(nowTime, startTime);
    const timeDifference = calculateTimeDifference(nowTime, endTime);
    const timeDifference2 = calculateTimeDifference(nowTime, startTime);
    const isStarted = calculateTimeLeft(nowTime, startTime);
    let title = eventInfo.event.title + ""
    let isBackgrounStyleSet = title.indexOf('@') // @가 포함되어 있는지 확인하고, 없으면 -1 있으면 index를 뱉음


    if(isBackgrounStyleSet !== -1){
        let backgroundStyle = title.substring(isBackgrounStyleSet + 1,)

        if (leftTime > 10 || leftTime < 0 || eventDuration < 3 || isStarted > 0 ) {
            return (
                <>
                    <i className={title.substring(0, 2) + " " + backgroundStyle} >{title.substring(3, isBackgrounStyleSet)}</i>
                </>
            );

        } else {
            return (
                <>
                    <i className={title.substring(0, 2) + " " + backgroundStyle} >{title.substring(3, isBackgrounStyleSet)}</i>
                    <div className='timeDifference'>{timeDifference}</div>
                </>
            );
        }
    } else {
        if (leftTime < 10 && leftTime >= 0 && eventDuration >= 0 && leftTime2 < 10 && leftTime2 >= 0) {
            return (
                <>
                    <i className={title.substring(0, 2)}>{title.substring(3,)}</i>
                    <div className='timeDifference2'>{timeDifference2}</div>        
                    <div className='timeDifference'>{timeDifference}</div>
                  
                </>
            );
         } else if (leftTime < 10 && leftTime >= 0 && eventDuration >= 0 && isStarted <= 0 ) {
            return (
                <>
                    <i className={title.substring(0, 2)}>{title.substring(3,)}</i>
                    <div className='timeDifference'>{timeDifference}</div>
                </>
            );
        } else if (leftTime2 < 10 && leftTime2 >= 0 && eventDuration >= 0 && isStarted >= 0 ) {
            return (
                <>
                    <i className={title.substring(0, 2)}>{title.substring(3,)}</i>
                    <div className='timeDifference2'>{timeDifference2}</div>        
                </>
            );
        } else {
            return (
                <>
                    <i className={title.substring(0, 2)}>{title.substring(3,)}</i>
                </>
            );
    }


}}

export default Calendar;
