import {useEffect} from "react";
import ReactGA from "react-ga";

const Analytics = () => {
  const pathName = window.location.pathname;
  useEffect(() => {
      ReactGA.initialize("G-FQXYKGNSJW");
      ReactGA.set({page: pathName});
      ReactGA.pageview(pathName);
 }, [pathName]);
  return <></>;
}

export default Analytics;