import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguaeDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguaeDetector) // 사용자 언어 탐지
  .use(initReactI18next) // i18n 객체를 react-18next에 전달
  .init({
    // for all options read: https://www.i18next.com/overview/configuration-options
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: { // 번역본 쓸 공간
          gaming_event_calendar : 'gaming event calendar',
          reverse : 'Reverse: 1999',
          starrail : 'Honkai: Starrail',
          genshin : 'Genshin Impact',
          nikke : 'NIKKE',
          blue_archive : 'Blue Archive'
        },
      },
      jp: {
        translation: { // 번역본 쓸 공간
          gaming_event_calendar : 'ゲームスケジュールカレンダー',
          reverse : 'リバース：1999',
          starrail : '崩壊：スターレイル',
          genshin : '原神',
          nikke : 'NIKKE',
          blue_archive : 'ブルーアーカイブ'
        },
      },
      kr: {
        translation: { // 번역본 쓸 공간
          gaming_event_calendar : "게이밍 이벤트 캘린더",
          reverse : '리버스: 1999',
          starrail : '붕괴: 스타레일',
          genshin : '원신',
          nikke : 'NIKKE',
          blue_archive : '블루 아카이브'
        },
      },
    },
  });

export default i18n;