import React from 'react';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { TikTok_hs } from '../pages/TikTokEmbed' //   불러와서 쓰는 방식 --> 어디서나 데려다가 사용하면 됨
import { X_hs_gb, X_hs_jp,X_hs_kr } from '../pages/XEmbeded' //   불러와서 쓰는 방식 --> 어디서나 데려다가 사용하면 됨


export function tdc2_hs_gb(mode) {
  return <X_hs_gb />
}

export function tdc2_hs_jp() {
    return <X_hs_jp/>
  
  }
export function tdc2_hs_kr() {
    return <X_hs_kr/>

}

export function tdc2_hs_cn() {
    return <div>
      <div className='bili'>

      <TikTok_hs/>
      </div>
    </div>;
}
