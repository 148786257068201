import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { getFunctions } from 'firebase/functions';
import { getFirestore, doc, getDoc, updateDoc,setDoc } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "pinacolada-8e8f9.firebaseapp.com",
  projectId: "pinacolada-8e8f9",
  storageBucket: "pinacolada-8e8f9.appspot.com",
  messagingSenderId: "101259036426",
  appId: "1:101259036426:web:dabb5c32debacddbd05cf3",
  measurementId: "G-FQXYKGNSJW"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const authService = firebase.auth();
export const functions = getFunctions(app);

// Firestore 초기화
const db = getFirestore();

export const getVotesCount = async (game, character) => {
  try {
    const docRef = doc(db, game, character);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data(); // { upvotes: x, downvotes: y }
    } else {
      console.log('No such document!');
      return { upvotes: 100, downvotes: 100 };
    }
  } catch (error) {
    console.error('Error getting document:', error);
    return { upvotes: 100, downvotes: 100 };
  }
};

export const updateVotesCount = async (game, character, type) => {
  try {
    const docRef = doc(db, game, character);
    const docSnap = await getDoc(docRef);

    let { upvotes = 100, downvotes = 100 } = docSnap.data() || {};

    if (type === "upvote") {
      upvotes += 1;
    } else if (type === "downvote") {
      downvotes += 1;
    }

    if (docSnap.exists()) {
      updateDoc(docRef, { upvotes, downvotes });
      console.log('vote update done')
      return docSnap.data(); // { upvotes: x, downvotes: y }
    } else {
      setDoc(docRef, { upvotes, downvotes });
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting document:', error);
    return null;
  }
};
