import React, { useEffect } from 'react';

const TikTok_hs = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@honkaistarrail_official" data-unique-id="honkaistarrail_official" data-embed-type="creator">
      <section>
        <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@honkaistarrail_official?refer=creator_embed">@honkaistarrail_official</a>
      </section>
    </blockquote>
  );
};

const TikTok_re = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@hulcobeamextras" data-unique-id="hulcobeamextras" data-embed-type="creator">
      <section>
        <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@hulcobeamextras?refer=creator_embed">@hulcobeamextras</a>
      </section>
    </blockquote>
  );
};


const TikTok_gs = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <blockquote className="tiktok-embed" cite="hhttps://www.tiktok.com/@genshinimpact_en" data-unique-id="genshinimpact_en" data-embed-type="creator">
      <section>
        <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@genshinimpact_en?refer=creator_embed">@hgenshinimpact_en</a>
      </section>
    </blockquote>
  );
};

export { TikTok_hs, TikTok_re, TikTok_gs };
