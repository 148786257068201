import React, { useEffect } from 'react';
import gs_ayaka from '../images/gs_ayaka.jpg'
import gs_navia from '../images/gs_navia.jpg'
import re_jessica from '../images/re_jessica.jpg'
import re_toothfairy from '../images/re_toothfairy.jpg'
import hs_blade from '../images/hs_blade.jpg'
import hs_ruanmei from '../images/hs_ruanmei.jpg'
import nk_bs from '../images/nk_bs.jpg'
import nk_modernia from '../images/nk_modernia.jpg'
import ba_camphare from '../images/ba_camphare.jpg'
import ba_campkotama from '../images/ba_campkotama.jpg'
import ba_mizugimiyako from '../images/ba_mizugimiyako.jpg'
import ba_mizugisaki from '../images/ba_mizugisaki.jpg'




// 각 게임에 맞게 투표가 진행되는 캐릭터들의 이름과 이미지를 넣어주세요.
const gs = [{name : 'ayaka2', img : gs_ayaka},{name : 'navia2', img : gs_navia}]
const ba = [{name : 'mizugimiyako01', img : ba_mizugimiyako},{name : 'mizugisaki01', img : ba_mizugisaki}]
const bajp = [{name : 'campkotama02', img : ba_campkotama},{name : 'camphare02', img : ba_camphare}]
const nk = [{name : 'bs01', img : nk_bs},{name : 'modernia01', img : nk_modernia}]
const hs = [{name : 'ruanmei01', img : hs_ruanmei},{name : 'blade01', img : hs_blade}]
const re = [{name : 'jessica02', img : re_jessica},{name : 'toothfairy01', img : re_toothfairy}]

const getVoteCharacterInfo = (game) =>{
    if(game === 'gs'){
        return gs
    }else if(game === 'nk'){
        return nk
    }else if (game === 'ba') {
        return ba
    }else if (game === 'bajp') {
        return bajp
    }else if (game === 'hs'){
        return hs
    }else if (game === 're'){
        return re
    }else {
        return 'need to add new game filter'
    }


}



export {getVoteCharacterInfo};
