import React, { useState, useRef, useEffect, createRef } from 'react';
import Calendar from './pages/Calender';
import Sidemenu from './pages/Sidemenu'; // Import the new component
import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 
import './Toggle.css'; // 스타일시트를 가져옵니다.
//import './light_App.css';
//import './light_App_m.css';
import './App.css'
//import './App_m.css'
import { tdc1_ba_jp, tdc1_ba_gb, tdc1_ba_cn, tdc1_ba_kr } from './tdc1/tdc1_ba'
import { tdc1_gs_jp, tdc1_gs_gb, tdc1_gs_cn, tdc1_gs_kr } from './tdc1/tdc1_gs'
import { tdc1_hs_jp, tdc1_hs_gb, tdc1_hs_cn, tdc1_hs_kr } from './tdc1/tdc1_hs'
import { tdc1_nk_jp, tdc1_nk_gb, tdc1_nk_cn, tdc1_nk_kr } from './tdc1/tdc1_nk'
import { tdc1_re_jp, tdc1_re_gb, tdc1_re_cn, tdc1_re_kr } from './tdc1/tdc1_re'
import { tdc2_ba_jp, tdc2_ba_gb, tdc2_ba_cn, tdc2_ba_kr } from './tdc2/tdc2_ba'
import { tdc2_gs_jp, tdc2_gs_gb, tdc2_gs_cn, tdc2_gs_kr } from './tdc2/tdc2_gs'
import { tdc2_hs_jp, tdc2_hs_gb, tdc2_hs_cn, tdc2_hs_kr } from './tdc2/tdc2_hs'
import { tdc2_nk_jp, tdc2_nk_gb, tdc2_nk_cn, tdc2_nk_kr } from './tdc2/tdc2_nk'
import { tdc2_re_jp, tdc2_re_gb, tdc2_re_cn, tdc2_re_kr } from './tdc2/tdc2_re'
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { isLabelWithInternallyDisabledControl } from '@testing-library/user-event/dist/utils';
import { useNavigate } from "react-router-dom";
import { Legal_ba, Legal_nk, Legal_gs, Legal_re, Legal_hs } from './pages/legal';
import { getMetas } from './pages/Seo'
import gs_ayaka from './images/gs_ayaka.png'
import gs_navia from './images/gs_navia.png'
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import { httpsCallable } from 'firebase/functions';
import { functions, getVotesCount, updateVotesCount } from './firebaseConfig';
import { getVoteCharacterInfo } from './pages/Vote'
import moment from 'moment-timezone';
import Analytics from "./Analytics";
import Switch from "react-switch";
import {
  X_hs_gb, X_hs_jp, X_hs_kr,
  X_ba_gb, X_ba_jp, X_ba_kr,
  X_gs_gb, X_gs_jp, X_gs_kr,
  X_nk_gb, X_nk_jp, X_nk_kr,
  X_re_gb, X_re_jp, X_re_kr
} from "./pages/XEmbeded";
import icon_re from './images/icon_re.jpg';
import icon_nk from './images/icon_nk.jpg';
import icon_ba from './images/icon_ba.jpg';
import icon_hs from './images/icon_hs.jpg';
import icon_gs from './images/icon_gs.jpg';




const App = () => {

  const [activeTab, setActiveTab] = useState('');
  const [showMenu, setShowMenu] = useState(false); // New state for showing the centered menu
  const [activeView, setActiveView] = useState('home'); // State to track the active view
  const [tabContents, setTabContents] = useState({});
  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언 
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('jp');
  const [tdc1Content, setTdc1Content] = useState('')
  const [tdc2Content, setTdc2Content] = useState('')
  const [LegalContents, setLegalContents] = useState('')
  const navigate = useNavigate()
  const [voteDataLoading, setVoteDataLoading] = useState(false);
  const [voteCharacters, setVoteCharacters] = useState([]);
  const [gameName, setGameName] = useState('')
  const [darkMode, setDarkmode] = useState(true)
  const [cssLoaded, setCssLoaded] = useState(false);
  const [scrollY, setScrollY] = useState(0); // 스크롤 위치 상태
  const [showNavBar, setShowNavBar] = useState(false); // 내비게이션 바 표시 여부 상태




  const languageBox = { "jp": "🇯🇵", "cn": "中文", "gb": "🇬🇧", "kr": "🇰🇷" }
  const gameBox = { "re": "Tab 1", "nk": "Tab 2", "ba": "Tab 3", "hs": "Tab 4", "gs": 'Tab 5' }
  const tdcBox = {
    tdc1: {
      ba: { jp: tdc1_ba_jp, gb: tdc1_ba_gb, cn: tdc1_ba_cn, kr: tdc1_ba_kr },
      gs: { jp: tdc1_gs_jp, gb: tdc1_gs_gb, cn: tdc1_gs_cn, kr: tdc1_gs_kr },
      hs: { jp: tdc1_hs_jp, gb: tdc1_hs_gb, cn: tdc1_hs_cn, kr: tdc1_hs_kr },
      nk: { jp: tdc1_nk_jp, gb: tdc1_nk_gb, cn: tdc1_nk_cn, kr: tdc1_nk_kr },
      re: { jp: tdc1_re_jp, gb: tdc1_re_gb, cn: tdc1_re_cn, kr: tdc1_re_kr }
    },
    tdc2: {
      ba: {
        jp: <X_ba_jp key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />,
        gb: <X_ba_gb key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />,
        cn: tdc2_ba_cn,
        kr: <X_ba_kr key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />
      },
      gs: {
        jp: <X_gs_jp key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />,
        gb: <X_gs_gb key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />,
        cn: tdc2_gs_cn,
        kr: <X_gs_kr key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />
      },
      hs: {
        jp: <X_hs_jp key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />,
        gb: <X_hs_gb key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />,
        cn: tdc2_hs_cn,
        kr: <X_hs_kr key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />
      },
      nk: {
        jp: <X_nk_jp key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />,
        gb: <X_nk_gb key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />,
        cn: tdc2_nk_cn,
        kr: <X_nk_kr key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />
      },
      re: {
        jp: <X_re_jp key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />,
        gb: <X_re_gb key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />,
        cn: tdc2_re_cn,
        kr: <X_re_kr key={darkMode ? 'dark' : 'light'} darkMode={darkMode} />
      }
    }

  };
  const twitterUrls = {
    hs_gb: "https://twitter.com/honkaistarrail",
    nk_gb: "https://twitter.com/NIKKE_en",
    gs_gb: "https://twitter.com/GenshinImpact?ref_src=twsrc%5Etfw",
    re_gb: "https://twitter.com/Reverse1999_GL",
    ba_gb: "https://twitter.com/EN_BlueArchive",
    gs_jp: "https://twitter.com/Genshin_7",
    hs_jp: "https://twitter.com/houkaistarrail",
    nk_jp: "https://twitter.com/NIKKE_japan",
    ba_jp: "https://twitter.com/Blue_ArchiveJP",
    re_jp: "https://twitter.com/Reverse1999_JP",
    gs_kr: "https://twitter.com/genshinimpactkr",
    hs_kr: "https://twitter.com/honkaisr_kr?ref_src=twsrc%5Etfw",
    ba_kr: "https://twitter.com/KR_BlueArchive",
    re_kr: "https://twitter.com/REVERSE1999KR",
    nk_kr: "https://twitter.com/NIKKE_kr"
  };


  const Legalbox = {
    ba: Legal_ba,
    gs: Legal_gs,
    hs: Legal_hs,
    nk: Legal_nk,
    re: Legal_re
  }

  const goToTop = () => {
    window.scrollTo({
      top: 0, // 페이지 맨 위로 이동
      behavior: 'smooth' // 부드러운 스크롤 효과 적용
    });
  };
  

  const toggleLanguageDropdown = () => {
    setShowLanguageDropdown(!showLanguageDropdown);
  };

  function getLanguage() {
    return navigator.language || navigator.userLanguage;
  }

  const handleLanguageChange = (language) => {
    setShowLanguageDropdown(false);
    const keysOfGamebox = Object.keys(gameBox);
    const gameboxKey = keysOfGamebox.find((key) => gameBox[key] === activeTab.toString());
    setGameName(gameboxKey)



    if (language === 'kr') {
      setSelectedLanguage('kr')
      i18n.changeLanguage('kr')
    } else if (language === 'gb') {
      i18n.changeLanguage('gb')
      setSelectedLanguage('gb')
    } else if (language === 'jp') {
      i18n.changeLanguage('jp')
      setSelectedLanguage('jp')
    }
    // else if (language === 'cn') {
    //   i18n.changeLanguage('cn')
    //   setSelectedLanguage('cn')
    // }

    navigate(`${language}/${gameboxKey}`)
    localStorage.setItem('Lan', language)
  };

  const handleTabClick = (tabName) => {
    if (activeTab === tabName) return; // 중복해서 같은턉 계속 클릭할때마다 재랜더링되지 않도록 하기

    setActiveTab(tabName);
    const keysOfGamebox = Object.keys(gameBox);
    localStorage.setItem('Tab', tabName)
    const gameboxKey = keysOfGamebox.find((key) => gameBox[key] === tabName);
    setGameName(gameboxKey)
    setLegalContents(Legalbox[gameboxKey])

    navigate(`${selectedLanguage}/${gameboxKey}`)
  };

  function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    if (activeTab === '' || gameName === '' || gameName === null || gameName === undefined) {
      return // 최초 세팅이 되어야 하는 값들이 세팅 되기 전까지 기다림 (url 읽기, 캐시 읽기) 
    }






    // 아래 코드는 탭 이동시 스크롤 보여주는 코드
    // var calendar_body = document.querySelectorAll('.fc-scroller-liquid-absolute');
    // var tabIndex = Number(activeTab.substring(3,))

    // if(calendar_body.length === 5){
    //   calendar_body[tabIndex-1].scrollTo({left:0, behavior:'instant'})
    //   wait(100) // Adjust the delay as needed
    // .then(() => {
    //   calendar_body[tabIndex-1].scrollTo({left:400, behavior:'smooth'})
    // });
    // }


    let newTabContents = {}
    if (selectedLanguage === 'jp') {
      // activeTab이 변경될 때마다 실행될 로직
      newTabContents = {
        'Tab 1': <div><Calendar Calendarid='c_a12b2b029dde4b7653a14a4c9dcccaff0e2b5df3507c1ababc2a78dd3e6c05c6@group.calendar.google.com' textContent="リバース：1999スケジュールー"/></div>,
        'Tab 2': <div><Calendar Calendarid='c_57225a732fcd78373e796f7577d7b401f67f001b7be059dbd8c7dcac9558c247@group.calendar.google.com' textContent="NIKKEスケジュールー"/></div>,
        'Tab 3': <div><Calendar Calendarid='c_9d9956995f3c1e6be8e49e9d2ce7cba52608269d2c5b8ee6d1be3c1c165234f8@group.calendar.google.com' textContent="ブルアカスケジュールー"/></div>,
        'Tab 4': <div><Calendar Calendarid='c_687d768b0bf95c0d9beaf465dadac149c24635c5b0c6619d1df9ab1def968d06@group.calendar.google.com' textContent="崩壊：スタレーイルスケジュールー"/></div>,
        'Tab 5': <div><Calendar Calendarid='c_7b42677f114d62844f699286d363133bc81fa0886260fad035a2b91fa03216b4@group.calendar.google.com' textContent="原神スケジュールー"/></div>,
        // 필요한 다른 탭 내용들을 여기에 추가
      };
    } else if (selectedLanguage === 'kr') {
      newTabContents = {
        'Tab 1': <div><Calendar Calendarid='c_2496b2f927144868668969b803fe4d62c511db9d54add528d20e938fd254d4e6@group.calendar.google.com' textContent="리버스:1999 일정"/></div>,
        'Tab 2': <div><Calendar Calendarid='c_1ba7f7de0e5486794d977259855c48223433ebcd98605230b45421e4d5a7e8e9@group.calendar.google.com' textContent="승리의 여신: 니케 일정"/></div>,
        'Tab 3': <div><Calendar Calendarid='c_60a742cdb44e1140eefc47897241e7fba82e95039507cee6fdde4bd033c786a3@group.calendar.google.com' textContent="블루 아카이브 일정"/></div>,
        'Tab 4': <div><Calendar Calendarid='c_a871975c71872992a61bdc3d87db5481e617b5b2cd5c113acad14414b021f9e3@group.calendar.google.com' textContent="붕괴: 스타레일 일정"/></div>,
        'Tab 5': <div><Calendar Calendarid='c_95e5c2c60610af0387681cf46fff1e1771fa6c35ad25f683e12dbf3fb0395f79@group.calendar.google.com' textContent="원신 일정"/></div>,
        // 필요한 다른 탭 내용들을 여기에 추가
      };
      // } else if (selectedLanguage === 'cn') {
      //   newTabContents = {
      //     'Tab 1': <div><Calendar Calendarid='c_040a01e9147c2474c9c0aede06adb9be41abcdf5db2fd0243aba96941346149b@group.calendar.google.com' /></div>,
      //     'Tab 2': <div><Calendar Calendarid='c_b41b0f008ca607d1d1e306544e754499f193714513c8bcf536805d041083da75@group.calendar.google.com' /></div>,
      //     'Tab 3': <div><Calendar Calendarid='c_9d9956995f3c1e6be8e49e9d2ce7cba52608269d2c5b8ee6d1be3c1c165234f8@group.calendar.google.com' /></div>,
      //     'Tab 4': <div><Calendar Calendarid='c_60a742cdb44e1140eefc47897241e7fba82e95039507cee6fdde4bd033c786a3@group.calendar.google.com' /></div>,
      //     'Tab 5': <div><Calendar Calendarid='c_b41b0f008ca607d1d1e306544e754499f193714513c8bcf536805d041083da75@group.calendar.google.com' /></div>,
      //     // 다른 탭들에도 필요한 CalendarId를 할당
      //   };
    } else if (selectedLanguage === 'gb') {
      newTabContents = {
        'Tab 1': <div><Calendar Calendarid='c_040a01e9147c2474c9c0aede06adb9be41abcdf5db2fd0243aba96941346149b@group.calendar.google.com' textContent="Reverse:1999 Schedule"/></div>,
        'Tab 2': <div><Calendar Calendarid='c_b41b0f008ca607d1d1e306544e754499f193714513c8bcf536805d041083da75@group.calendar.google.com' textContent="NIKKE Schedule"/></div>,
        'Tab 3': <div><Calendar Calendarid='c_ee7a98c5ffdab88db67ea9b80b54752329fd4b0fa119e2fb7073917202317fac@group.calendar.google.com' textContent="Blue Archive Calendar"/></div>,
        'Tab 4': <div><Calendar Calendarid='c_4166bcbb67b1e94b1425c9a320b1f1e35fdcd068a6d73b0cac7bec5aba2efb72@group.calendar.google.com' textContent="Houkai: Starrail Calendar"/></div>,
        'Tab 5': <div><Calendar Calendarid='c_89724c326822c8a977557811d831629297bbbef64c9f7bcea75dc746bd3c983a@group.calendar.google.com' textContent="Genshin Impact Calendar"/></div>,
        // 다른 탭들에도 필요한 CalendarId를 할당
      };
    } else {
      newTabContents = {
        'Tab 1': <div><Calendar Calendarid='c_040a01e9147c2474c9c0aede06adb9be41abcdf5db2fd0243aba96941346149b@group.calendar.google.com' textContent="Reverse:1999 Schedule"/></div>,
        'Tab 2': <div><Calendar Calendarid='c_b41b0f008ca607d1d1e306544e754499f193714513c8bcf536805d041083da75@group.calendar.google.com' textContent="NIKKE Schedule"/></div>,
        'Tab 3': <div><Calendar Calendarid='c_ee7a98c5ffdab88db67ea9b80b54752329fd4b0fa119e2fb7073917202317fac@group.calendar.google.com' textContent="Blue Archive Calendar"/></div>,
        'Tab 4': <div><Calendar Calendarid='c_4166bcbb67b1e94b1425c9a320b1f1e35fdcd068a6d73b0cac7bec5aba2efb72@group.calendar.google.com' textContent="Houkai: Starrail Calendar"/></div>,
        'Tab 5': <div><Calendar Calendarid='c_89724c326822c8a977557811d831629297bbbef64c9f7bcea75dc746bd3c983a@group.calendar.google.com' textContent="Genshin Impact Calendar"/></div>,
        // 다른 탭들에도 필요한 CalendarId를 할당
      };
    }

    setTabContents(newTabContents);


    const keysOfGamebox = Object.keys(gameBox);
    if (activeTab) {
      const gameboxKey = keysOfGamebox.find((key) => gameBox[key] === activeTab.toString());
      setGameName(gameboxKey)
      let voteChar = getVoteCharacterInfo(gameboxKey)
      setVoteCharacters(voteChar)

      if (gameboxKey === 'ba' && selectedLanguage === 'jp') {
        loadVotes(getVoteCharacterInfo('bajp'), activeTab)
      } else {
        loadVotes(voteChar, activeTab); // 투표 현황 가져오기
      }

      // tdc 컨텐츠 설정 코드 단순화
      setTdc1Content(tdcBox['tdc1'][gameboxKey][selectedLanguage])
      setTdc2Content(tdcBox['tdc2'][gameboxKey][selectedLanguage])
      setLegalContents(Legalbox[gameboxKey]);

    } else {
      console.error('Tab is something wrong')
    }



  }, [activeTab, selectedLanguage, gameName,darkMode]); // 국기와 게임 타이틀이 바뀔때 마다 새로고침

  const voteFunction = httpsCallable(functions, 'checkVoted');

  const loadVotes = async (data, currentTab) => {
    setVoteDataLoading(false)


    try {
      const voteRequests = data.map(async (character) => {

        if (!gameName || !character.name) {
          //console.error('Invalid gameName or character name');
          return null;
        }
        const localHasVoted = hasVotedToday((gameName === 'ba' && selectedLanguage === 'jp') ? 'bajp' : gameName, character.name)
        const votesCountPromise = getVotesCount((gameName === 'ba' && selectedLanguage === 'jp') ? 'bajp' : gameName, character.name);
        const voteCheckPromise = voteFunction({ gameName: (gameName === 'ba' && selectedLanguage === 'jp') ? 'bajp' : gameName, charName: character.name });

        const [votesCount, voteCheckResult] = await Promise.all([votesCountPromise, voteCheckPromise]);
        const hasVoted = voteCheckResult.data.voted;
        const votedType = voteCheckResult.data.voteType;

        return {
          name: character.name,
          img: character.img,
          upvotes: votesCount.upvotes,
          downvotes: votesCount.downvotes,
          hasVoted: (localHasVoted.hasVoted === false && hasVoted === false) ? false : true,
          votedType: votedType === null ? localHasVoted.votedType : votedType
        };
      });

      const voteResults = await Promise.all(voteRequests);
      if (currentTab === activeTab) { // 탭을 빠르게 이동 시, 이전 탭 값이 겹쳐써지는 이슈가 있어서 지금 탭 확인코드 추가
        setVoteCharacters(voteResults.filter(v => v !== null));
        setVoteDataLoading(true);

      }

    } catch (error) {
      console.log(error)
    }
  };


  useEffect(() => {
    //addCSS();
    // url 정보 읽기

    let url = window.location.pathname
    if (url.endsWith('/')) {
      url = url.slice(0, -1);
    }

    // URL을 '/' 기준으로 분리합니다.
    const segments = url.split('/');

    const languageURL = segments[segments.length - 2]; // 끝에서 두 번째 세그먼트
    const tabURL = segments[segments.length - 1];
    // let languageURL = urlpath.substring(urlpath.length-5, urlpath.length-3)
    // let tabURL = urlpath.substring(urlpath.length-2, urlpath.length)

    // 기기 정보 읽기
    let firstTab = localStorage.getItem('Tab')
    let firstLan = localStorage.getItem('Lan')


    if (tabURL !== null && tabURL !== "" && gameBox[tabURL]) {
      setActiveTab(gameBox[tabURL])
      const keysOfGamebox = Object.keys(gameBox);
      const gameboxKey = keysOfGamebox.find((key) => gameBox[key] === gameBox[tabURL].toString());
      setGameName(gameboxKey)
    } else if (firstTab !== null) {
      setActiveTab(firstTab)
      const keysOfGamebox = Object.keys(gameBox);
      const gameboxKey = keysOfGamebox.find((key) => gameBox[key] === firstTab.toString());
      setGameName(gameboxKey)
    } else {
      setGameName('re')
      setActiveTab('Tab 1')
    }


    let browserLan = getLanguage()
    let LanCode = browserLan.toString().substring(0, 2) // 가끔 국가 코드도 뒤에 붙어서 국가코드는 잘라버림



    if (languageBox[languageURL]) {
      setSelectedLanguage(languageURL)
      i18n.changeLanguage(languageURL)

    } else if (firstLan !== null) {
      setSelectedLanguage(firstLan)
      i18n.changeLanguage(firstLan)
    } else if (LanCode === 'ko') {
      setSelectedLanguage("kr")
      i18n.changeLanguage('kr')
    } else if (LanCode === 'ja') {
      setSelectedLanguage('jp')
      i18n.changeLanguage('jp')

    } else {
      setSelectedLanguage('gb')
      i18n.changeLanguage('gb')

    }



  }, []); // 최초 랜더링 시 한번만 진행

  function toggleDarkMode() {
    if (darkMode){
      document.documentElement.classList.remove("light")
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
      document.documentElement.classList.add("light")
    }
  }



  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const scrollYInVH = (scrollY / window.innerHeight) * 100; // px를 vh 단위로 변환
    if (scrollYInVH > 24) { // 예시로 5vh 이상인 경우로 설정
      setShowNavBar(true);
    } else {
      setShowNavBar(false);
    }
  }, [scrollY]);


  
  useEffect(() => {
    toggleDarkMode()
  }, [darkMode]); // darkMode 상태가 변경될 때마다 addCSS 함수 호출

  
  const handleSidebarClick = () => {
    setActiveView('menu'); // Change view to the menu
  };

  const handleMenuClose = () => {
    setActiveView('home'); // Change view back to the home/calendar view
  };
  const recordVoteFunction = httpsCallable(functions, 'recordVote');

  const handleVote = (index, type) => {
    const character = voteCharacters[index];
    const locaVotesData = hasVotedToday((gameName === 'ba' && selectedLanguage === 'jp') ? 'bajp' : gameName, character.name)

    if (voteCharacters[index].hasVoted) {
      //alert('1 vote / day');
      return;
    }

    if (locaVotesData.hasVoted === true) {
      //alert('1 vote / day');
      return;
    }


    try {
      recordVoteFunction({ gameName: (gameName === 'ba' && selectedLanguage === 'jp') ? 'bajp' : gameName, charName: character.name, voteType: type });
      updateVotesCount((gameName === 'ba' && selectedLanguage === 'jp') ? 'bajp' : gameName, character.name, type);
      setVoteInLocalStorage((gameName === 'ba' && selectedLanguage === 'jp') ? 'bajp' : gameName, character.name, type);

      // 새로운 상태 즉각 반영을 위한 코드
      const updatedVoteCharacters = voteCharacters.map((ch, idx) => {
        if (idx === index) {
          return {
            ...ch,
            hasVoted: true,
            upvotes: type === 'upvote' ? ch.upvotes + 1 : ch.upvotes,
            downvotes: type === 'downvote' ? ch.downvotes + 1 : ch.downvotes,
            votedType: type
          };
        }
        return ch;
      });

      setVoteCharacters(updatedVoteCharacters);

      // 아래에 투표 완료 이후 하고 싶은 로직 있으면 추가 가능
      //alert('done!');
    } catch (error) {
      console.error('error:', error);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleDarkmode = () => {
    setDarkmode(!darkMode)
  };
  
  

  function getKSTDate() {
    return moment().tz("Asia/Seoul").startOf('day').add(4, 'hours');
  }

  function setVoteInLocalStorage(gameName, charName, votedType) {
    const kstToday = getKSTDate().format('YYYY-MM-DD');
    const votesData = JSON.parse(localStorage.getItem('votesData')) || {};
    votesData.lastVotedDate = kstToday;
    votesData.votes = votesData.votes || {};
    votesData.votes[gameName] = votesData.votes[gameName] || {};
    votesData.votes[gameName][charName] = votedType;
    localStorage.setItem('votesData', JSON.stringify(votesData));
  }

  function hasVotedToday(gameName, charName) {
    const kstToday = getKSTDate().format('YYYY-MM-DD');
    const votesData = JSON.parse(localStorage.getItem('votesData'));

    if (votesData && votesData.lastVotedDate === kstToday) {
      if (votesData.votes && votesData.votes[gameName] && votesData.votes[gameName][charName]) {
        // 특정 게임의 특정 캐릭터에 대한 투표 정보가 있을 경우
        return {
          hasVoted: true,
          votedType: votesData.votes[gameName][charName] // 'upvote' 혹은 'downvote'
        };
      }
    }
    // 투표 정보가 없거나 마지막 투표 날짜가 오늘 날짜가 아닌 경우
    return {
      hasVoted: false,
      votedType: null
    };
  }

  return (



    <div className="home">

      <Analytics />
      {getMetas(gameName, selectedLanguage)}

      {/* <script async src='https://www.tiktok.com/embed.js'></script> */}

      <div className='header_container'>
      {showNavBar && (
        <nav id="nav-bar" className="nav-bar"
        onClick= {goToTop}
        >
          <div className='game_title_container_nav'>
          {/* <p
            className={`game_title ${activeTab === 'Tab 1' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 1')}
          >
            <img className='icon' src={icon_re} alt={t('reverse')}></img>
          </p>
          <p
            className={`game_title ${activeTab === 'Tab 2' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 2')}
          >
            <img className='icon' src={icon_nk} alt={t('nikke')}></img>
          </p>
          <p
            className={`game_title ${activeTab === 'Tab 3' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 3')}
          >
            <img className='icon' src={icon_ba} alt={t('blue_archive')}></img>
          </p>
          <p
            className={`game_title ${activeTab === 'Tab 4' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 4')}
          >
            <img className='icon' src={icon_hs} alt={t('starrail')}></img>
          </p>
          <p
            className={`game_title ${activeTab === 'Tab 5' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 5')}
          >
            <img className='icon' src={icon_gs} alt={t('genshin')}></img>
          </p> */}
              <div
                className={`game_title ${activeTab === 'Tab 1' ? 'active-tab' : ''}`}
                onClick={() => handleTabClick('Tab 1')}
                style={{ display: activeTab === 'Tab 1' ? 'flex' : 'none' }}
              >
            <img className='icon' src={icon_re} alt={t('reverse')}></img>
       
          </div>
          <div
            className={`game_title ${activeTab === 'Tab 2' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 2')}
            style={{ display: activeTab === 'Tab 2' ? 'block' : 'none' }}
          >
            <img className='icon' src={icon_nk} alt={t('nikke')}></img>
            {t('nikke')}
          </div>
          <div
            className={`game_title ${activeTab === 'Tab 3' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 3')}
            style={{ display: activeTab === 'Tab 3' ? 'block' : 'none' }}
          >
            <img className='icon' src={icon_ba} alt={t('blue_archive')}></img>
          </div>
          <div
            className={`game_title ${activeTab === 'Tab 4' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 4')}
            style={{ display: activeTab === 'Tab 4' ? 'block' : 'none' }}
          >
            <img className='icon' src={icon_hs} alt={t('starrail')}></img>
          </div>
          <div
            className={`game_title ${activeTab === 'Tab 5' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 5')}
            style={{ display: activeTab === 'Tab 5' ? 'block' : 'none' }}
          >
            <img className='icon' src={icon_gs} alt={t('genshin')}></img>
          
          </div>
          {tabContents[activeTab].props.children.props.textContent}

          </div>
        </nav>
      )}      <div className="toggle-switch-container">
      <label className="toggle-switch">
        <input type="checkbox" checked={darkMode} onChange={handleDarkmode} />
        <div className="slider"></div>
      </label>
    </div>
        <div className='sidebar' onClick={handleSidebarClick}></div>
        <div className='sidemenu_container'>
          <div className="menu-item_lan2" onClick={toggleLanguageDropdown}>
            <div className='selected_lan2'>{languageBox[`${selectedLanguage}`] === undefined ? '🇬🇧' : languageBox[`${selectedLanguage}`]}</div>
          </div>

          {showLanguageDropdown && (
            <div className="language-dropdown2">
              {selectedLanguage !== 'gb' && (
                <div className='selected_lan2' onClick={() => handleLanguageChange('gb')}>
                  🇬🇧
                </div>
              )}
              {selectedLanguage !== "kr" && (
                <div className='selected_lan2' onClick={() => handleLanguageChange('kr')}>
                  🇰🇷
                </div>
              )}
              {selectedLanguage !== "jp" && (
                <div className='selected_lan2' onClick={() => handleLanguageChange('jp')}>
                  🇯🇵
                </div>
              )}
              {/* {selectedLanguage !== "cn" && (
                <div className='selected_lan2' onClick={() => handleLanguageChange('cn')}>
                  中文
                </div>
              )} */}
              {/* ... 나머지 언어 옵션 ... */}
            </div>
          )}
          {/* <div className='sidemenu'> Home </div>
          <div className='sidemenu'> Contact </div>
          <div className='sidemenu'> About </div> */}
        </div>
      </div>
      <div className='title_container' >
        <h1 className='title' onClick={handleRefresh}>
          gacha.live
        </h1>
        <h2 className='subtitle'>{t('gaming_event_calendar')}</h2>
      </div>

      <div className='main_content_container'>
        <div className='menu'>
        <div className='game_title_container'>
          <p
            className={`game_title ${activeTab === 'Tab 1' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 1')}
          >
            <img className='icon' src={icon_re} alt={t('reverse')}></img>
          </p>
          <p
            className={`game_title ${activeTab === 'Tab 2' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 2')}
          >
            <img className='icon' src={icon_nk} alt={t('nikke')}></img>
          </p>
          <p
            className={`game_title ${activeTab === 'Tab 3' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 3')}
          >
            <img className='icon' src={icon_ba} alt={t('blue_archive')}></img>
          </p>
          <p
            className={`game_title ${activeTab === 'Tab 4' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 4')}
          >
            <img className='icon' src={icon_hs} alt={t('starrail')}></img>
          </p>
          <p
            className={`game_title ${activeTab === 'Tab 5' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('Tab 5')}
          >
            <img className='icon' src={icon_gs} alt={t('genshin')}></img>
          </p>
          </div>
          <div className='legal'>{LegalContents}</div>

        </div>
        <div className="tab-content">
        
          {Object.keys(tabContents).map((tab) => (
            <div
              key={tab}
              className={`calendar-container ${activeTab === tab ? 'active' : 'inactive'}`}
            >
              {tabContents[tab]}
            </div>
          ))}
        </div>

        <div className='todo-title'>
          <div className='pollcontainer'>
            {!voteDataLoading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div className='poll'>
                {voteCharacters.length > 0 && voteCharacters.map((character, index) => ( // 투표할 캐릭터들의 숫자만큼 캐릭터 및 배경 자동 생성
                  <div
                    key={gameName + character.name + index}
                    className='pollsection'
                    style={{
                      backgroundImage: `url(${character.img})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className="pollup" style={{ flex: `${character.upvotes}` }}>
                      <button
                        onClick={() => handleVote(index, 'upvote')}
                        disabled={!voteDataLoading}
                        className={`pollbutton1 ${character.hasVoted === true ? 'polldone' : ''} ${character.hasVoted ? (character.votedType === 'upvote' ? 'polldone1' : '') : ''}`}>✔︎</button>

                      <div className={`upvote ${character.hasVoted ? (character.votedType === 'upvote' ? 'upvote_voted' : 'upvote_unvoted') : ''}`}>{(100 * character.upvotes / (character.upvotes + character.downvotes)).toFixed(1)}%</div>
                      {/* 투표를 이미 한 상황이면, 투표 버튼에 polldone classname 추가 */}
                    </div>
                    <div className={`polldown`} style={{ flex: `${character.downvotes}` }}>
                      <div className={`downvote ${character.hasVoted ? (character.votedType === 'downvote' ? 'downvote_voted' : 'downvote_unvoted') : ''}`} style={{ opacity: 1 }}>{(100 * character.downvotes / (character.upvotes + character.downvotes)).toFixed(1)}%</div>

                      <button
                        onClick={() => handleVote(index, 'downvote')}
                        disabled={!voteDataLoading}
                        className={`pollbutton2 ${character.hasVoted === true ? 'polldone' : ''} ${character.hasVoted ? (character.votedType === 'downvote' ? 'polldone2' : '') : ''}  `}>✔︎</button>
                      {/* <div>비추천 수: {character.downvotes}</div> */}
                    </div>
                  </div>
                ))}
              </div>)}
          </div>

          <div id="tdc1" className='tdc1'>
            {tdc1Content}
          </div>
          <div id="tdc2" className='tdc2'>
            <div className='tdc2container'>
              {tdc2Content}

              {/* <X_hs_gb key={darkMode ? 'dark' : 'light'} darkMode={darkMode} /> */}

            </div>
          </div>
        </div>
      </div>
      {activeView === 'menu' && (
        <Sidemenu onMenuClose={handleMenuClose} />
      )}
    </div>

  );

}

export default App;