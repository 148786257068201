import React from 'react';

const Legal_ba = "All game contents and assets used are the property and copyright of NEXON GAMES & YOSTAR"
const Legal_gs = "All game contents and assets used are the property and copyright of COGNOSPHERE PTE. LTD."
const Legal_re = "All game contents and assets used are the property and copyright of Bluepoch Co.,Ltd."
const Legal_hs = "All game contents and assets used are the property and copyright of COGNOSPHERE PTE. LTD."
const Legal_nk = "All game contents and assets used are the property and copyright of Proxima Beta Pte. Ltd. & SHIFT UP CORP."






export {Legal_ba, Legal_gs, Legal_hs, Legal_re, Legal_nk}