import React from 'react';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { X_ba_gb, X_ba_jp,X_ba_kr } from '../pages/XEmbeded' //   불러와서 쓰는 방식 --> 어디서나 데려다가 사용하면 됨




export function tdc2_ba_gb() {
    return  <X_ba_gb/>
}

export function tdc2_ba_jp() {
    return  <X_ba_jp/>
}

export function tdc2_ba_kr() {
    return  <X_ba_kr/>
}

export function tdc2_ba_cn() {
    return <div>끝없는 장강의 물결은 웅장하고 도도하게 흐른다</div>;
}
