import React from 'react';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { X_nk_gb, X_nk_jp,X_nk_kr } from '../pages/XEmbeded' //   불러와서 쓰는 방식 --> 어디서나 데려다가 사용하면 됨



export function tdc2_nk_gb() {
  return <X_nk_gb/>
}

export function tdc2_nk_jp() {
    return <X_nk_jp/>
}
export function tdc2_nk_kr() {
    return <X_nk_kr/>

}

export function tdc2_nk_cn() {
    return <div>
           <X_nk_gb/>

    </div>;
}
