import React from 'react';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { TikTok_gs } from '../pages/TikTokEmbed' //   불러와서 쓰는 방식 --> 어디서나 데려다가 사용하면 됨
import { X_gs_gb, X_gs_jp,X_gs_kr } from '../pages/XEmbeded' //   불러와서 쓰는 방식 --> 어디서나 데려다가 사용하면 됨


export function tdc2_gs_gb() {
  return <X_gs_gb/>
}

export function tdc2_gs_jp() {
    return<X_gs_jp/>
}

export function tdc2_gs_kr() {
    return <X_gs_kr/>
}

export function tdc2_gs_cn() {
    return <div>
         <div className='bili'>
      <TikTok_gs/>
      </div>


    </div>;
}
