import React, { useEffect } from 'react';

const createComponent = (url) => {
  return ({ darkMode }) => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, []);

    return (
      <div><a className="twitter-timeline" data-dnt="true" data-theme={darkMode ? 'dark' : 'light'} href={url}></a></div>
    );
  };
};

const X_hs_gb = createComponent("https://twitter.com/honkaistarrail");
const X_nk_gb = createComponent("https://twitter.com/NIKKE_en");
const X_gs_gb = createComponent("https://twitter.com/GenshinImpact");
const X_re_gb = createComponent("https://twitter.com/Reverse1999_GL");
const X_ba_gb = createComponent("https://twitter.com/EN_BlueArchive");
const X_gs_jp = createComponent("https://twitter.com/Genshin_7");
const X_hs_jp = createComponent("https://twitter.com/houkaistarrail");
const X_nk_jp = createComponent("https://twitter.com/NIKKE_japan");
const X_ba_jp = createComponent("https://twitter.com/Blue_ArchiveJP");
const X_re_jp = createComponent("https://twitter.com/Reverse1999_JP");
const X_gs_kr = createComponent("https://twitter.com/genshinimpactkr");
const X_hs_kr = createComponent("https://twitter.com/honkaisr_kr");
const X_ba_kr = createComponent("https://twitter.com/KR_BlueArchive");
const X_re_kr = createComponent("https://twitter.com/REVERSE1999KR");
const X_nk_kr = createComponent("https://twitter.com/NIKKE_kr");

export {
  X_hs_gb, X_nk_gb, X_gs_gb, X_re_gb, X_ba_gb,
  X_hs_jp, X_nk_jp, X_gs_jp, X_re_jp, X_ba_jp,
  X_hs_kr, X_gs_kr, X_nk_kr, X_re_kr, X_ba_kr
};
