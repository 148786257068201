import React from 'react';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';


export function tdc1_ba_gb() {
  return <div className="textbox">
<span>
<b>gacha.live</b> is provided to help everyone have better gaming experience and manage in-game purchases strategically. 
<br></br>
- Region change at top-right.
<br></br>
- Pull? vote once/day.
</span>
</div>;
}

export function tdc1_ba_jp() {
    return <div className="textbox">
<span><b>
gacha.live</b>は、誰もがゲームをプレイし、ゲーム内での課金を戦略的かつ効果的に管理できるように提供されている非公式のサービスです。
<br>
</br>右上から地域とサーバーを選択でき、1日1回 「引くべき？」に参加できます。
</span>
    </div>
}

export function tdc1_ba_kr() {
  return <div className="textbox">
<span>
<b>gacha.live</b> is provided to help everyone have better gaming experience and manage in-game purchases strategically. 
<br></br>
- Region change at top-right.
<br></br>
- Pull? vote once/day.
</span>
</div>;
}
export function tdc1_ba_cn() {
    return <div>ㅁㄹ끝없는 장강의 물결은 웅장하고 도도하게 흐른다</div>;
}
