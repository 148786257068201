
import React from 'react';
import { Helmet } from 'react-helmet-async';


export function ReMetas(language) {
    if(language === 'jp'){
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="リバース：1999カレンダー" />
              <meta property="og:url" content="https://gacha.live/jp/re" />
              <meta property="og:description" content="リバース：1999カレンダー" />
              <meta name="title" content="リバース：1999カレンダー" />
              <meta name="description" content="リバース：1999カレンダー" />
              <meta name="keywords" content="リバース：1999カレンダー" />
            </Helmet>
          );
    }else if (language === 'kr'){
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="리버스: 1999 캘린더" />
              <meta property="og:url" content="https://gacha.live/kr/re" />
              <meta property="og:description" content="리버스: 1999 캘린더" />
              <meta name="title" content="리버스: 1999 캘린더" />
              <meta name="description" content="리버스：1999" />
              <meta name="keywords" content="리버스: 1999 캘린더" />
            </Helmet>
          );
    }else {
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="Reverse: 1999 Calendar" />
              <meta property="og:url" content="https://gacha.live/gb/re" />
              <meta property="og:description" content="Reverse: 1999 Calendar" />
              <meta name="title" content="Reverse: 1999 Calendar" />
              <meta name="description" content="Reverse: 1999 Event Schedule Calendar" />
              <meta name="keywords" content="Reverse: 1999, Event, Schedule, Calendar, Reverse 1999 banner, banner" />
            </Helmet>
          );
    }
}


export function NkMetas(language) {
    if(language === 'jp'){
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="NIKKEカレンダー" />
              <meta property="og:url" content="https://gacha.live/jp/nk" />
              <meta property="og:description" content="NIKKEカレンダー" />
              <meta name="title" content="NIKKEカレンダー" />
              <meta name="description" content="NIKKEカレンダー" />
              <meta name="keywords" content="NIKKEカレンダー,メガニケ,勝利の女神ニケ,カレンダー" />
            </Helmet>
          );
    }else if (language === 'kr'){
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="NIKKE 캘린더" />
              <meta property="og:url" content="https://gacha.live/kr/nk" />
              <meta property="og:description" content="NIKKE 캘린더" />
              <meta name="title" content="NIKKE 캘린더" />
              <meta name="description" content="NIKKE 캘린더" />
              <meta name="keywords" content="NIKKE 캘린더, 니케, 승리의여신니케, 니케 이벤트" />
            </Helmet>
          );
    }else {
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="NIKKE Calendar" />
              <meta property="og:url" content="https://gacha.live/gb/nk" />
              <meta property="og:description" content="NIKKE Calendar" />
              <meta name="title" content="NIKKE Calendar" />
              <meta name="description" content="NIKKE Calendar" />
              <meta name="keywords" content="NIKKE Calendar, Goddes of victory NIKKE,
              event, nikke schedule, nikke event, nikke pull, nikke banner" />
            </Helmet>
          );
    }
}


export function BaMetas(language) {
    if(language === 'jp'){
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="ブルーアーカイブカレンダー" />
              <meta property="og:url" content="https://gacha.live/jp/ba" />
              <meta property="og:description" content="ブルーアーカイブカレンダー" />
              <meta name="title" content="ブルーアーカイブカレンダー" />
              <meta name="description" content="ブルーアーカイブカレンダー" />
              <meta name="keywords" content="ブルーアーカイブカレンダー, 
              カレンダー,ブルアカ、ブルアカフェス,ブルアカ予定" />
            </Helmet>
          );
    }else if (language === 'kr'){
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="블루아카이브 캘린더" />
              <meta property="og:url" content="https://gacha.live/kr/ba" />
              <meta property="og:description" content="블루아카이브 캘린더" />
              <meta name="title" content="블루아카이브 캘린더" />
              <meta name="description" content="블루아카이브 캘린더" />
              <meta name="keywords" content="블루아카이브 캘린더, 블루아카이브,
              블아, 몰루, 블루아카이벤트, 블루아카이브픽업" />
            </Helmet>
          );
    }else {
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="Blue Archive Calendar" />
              <meta property="og:url" content="https://gacha.live/gb/ba" />
              <meta property="og:description" content="Blue Archive Calendar" />
              <meta name="title" content="Blue Archive Calendar" />
              <meta name="description" content="Blue Archive Calendar" />
              <meta name="keywords" content="Blue Archive Calendar,
              Blue Archive, Buruaka, Blue Archive Banner, Blue Archive Event" />
            </Helmet>
          );
    }
}


export function HsMetas(language) {
    if(language === 'jp'){
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="崩壊：スターレイルカレンダー" />
              <meta property="og:url" content="https://gacha.live/jp/hs" />
              <meta property="og:description" content="崩壊：スターレイル" />
              <meta name="title" content="崩壊：スターレイルカレンダー" />
              <meta name="description" content="崩壊：スターレイルカレンダー" />
              <meta name="keywords" content="崩壊：スターレイルカレンダー,
              崩壊：スターレイル, 崩壊, 崩壊ガチャ
              " />  
            </Helmet>
          );
    }else if (language === 'kr'){
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="붕괴: 스타레일 캘린더" />
              <meta property="og:url" content="https://gacha.live/kr/hs" />
              <meta property="og:description" content="붕괴: 스타레일 캘린더" />
              <meta name="title" content="붕괴: 스타레일 캘린더" />
              <meta name="description" content="붕괴: 스타레일 캘린더" />
              <meta name="keywords" content="붕괴: 스타레일 캘린더,
               붕괴 캘린더, 붕괴 일정, 붕괴 이벤트, 붕괴 픽업, 붕스 픽업, 붕스 캘린더" />
            </Helmet>
          );
    }else {
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="Honkai: Starrail Calendar" />
              <meta property="og:url" content="https://gacha.live/gb/hs" />
              <meta property="og:description" content="Honkai: Starrail Calendar" />
              <meta name="title" content="Honkai: Starrail Calendar" />
              <meta name="description" content="Honkai: Starrail Calendar" />
              <meta name="keywords" content="Honkai: Starrail Calendar, 
              Honkai Calendar, Honkai Banner, Starrail Banner, Starrail Event" />
            </Helmet>
          );
    }
}


export function GsMetas(language) {
    if(language === 'jp'){
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="原神カレンダー" />
              <meta property="og:url" content="https://gacha.live/jp/gs" />
              <meta property="og:description" content="原神カレンダー" />
              <meta name="title" content="原神カレンダー" />
              <meta name="description" content="原神カレンダー" />
              <meta name="keywords" content="原神カレンダー, 原神ガチャ, 原神イベント" />
            </Helmet>
          );
    }else if (language === 'kr'){
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="원신 캘린더" />
              <meta property="og:url" content="https://gacha.live/kr/gs" />
              <meta property="og:description" content="원신" />
              <meta name="title" content="원신 캘린더" />
              <meta name="description" content="원신 캘린더" />
              <meta name="keywords" content="원신 캘린더, 
              원신 가챠, 원신 이벤트, 원신 복각, 원신 일정" />
            </Helmet>
          );
    }else {
        return (
            <Helmet>
              <meta property="og:site_name" content="gacha.live" />
              <meta property="og:title" content="Genshin Impact Calendar" />
              <meta property="og:url" content="https://gacha.live/gb/gs" />
              <meta property="og:description" content="Genshin Impact Calendar" />
              <meta name="title" content="Genshin Impact Calendar" />
              <meta name="description" content="Genshin Impact Calendar" />
              <meta name="keywords" content="Genshin Impact Calendar, 
              Genshin Event, Genshin Impact, Genshin Banner, Genshin Rerun" />
            </Helmet>
          );
    }
}


export function getMetas(game, language) {
    if(game === 'gs'){
        return GsMetas(language)
    }else if(game === 'nk'){
        return NkMetas(language)
    }else if (game === 'ba') {
        return BaMetas(language)
    }else if (game === 'hs'){
        return HsMetas(language)
    }else if (game === 're'){
        return ReMetas(language)
    }else {
        return ''
    }
}
