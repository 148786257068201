import React from 'react';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { X_re_gb, X_re_jp,X_re_kr } from '../pages/XEmbeded' //   불러와서 쓰는 방식 --> 어디서나 데려다가 사용하면 됨


export function tdc2_re_gb() {
  return <X_re_gb/>
}

export function tdc2_re_jp() {
    return <X_re_jp/>
}

export function tdc2_re_kr() {
    return <X_re_kr/>
}

export function tdc2_re_cn() {
    return <div>
      <div className='bili'>
      <iframe src="//player.bilibili.com/player.html?aid=408493134&bvid=BV1PG411D7nH&cid=1335422407&p=1" height="100%" width="100%" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
</div>
    </div>;
}
